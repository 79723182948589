<template>
    <v-col sm12 :md="size">
        <v-toolbar flat height="45">
            <v-toolbar-title>{{title}}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon v-show="showhide" @click.prevent="showHide()">
                <v-icon>fas fa-chevron-up</v-icon>
            </v-btn>
            <v-btn icon v-show="!showhide" @click.prevent="showHide()">
                <v-icon>fas fa-chevron-down </v-icon>
            </v-btn>
        </v-toolbar>
        <v-card v-show="showhide">
            <component v-bind:is="component" v-bind:period="period"></component>
        </v-card>
    </v-col>
</template>
<script>


    export default {
        props:['widgetData', 'period'],
        data() {
            return {
                component: null,
                showhide: true,
                title: '',
                size: 12,
            }
        },
        methods: {
            showHide: function () {
                this.showhide = !this.showhide
            },

        },
        beforeMount() {
            this.component = () => import(`${this.widgetData.link}`)
            this.title = this.widgetData.title

            try {
                 this.size = this.widgetData.size
            } catch{
            }

        }
    }
</script>


<style>

    .red {
        color: #F44336;
    }
</style>